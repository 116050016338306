import { sortByName } from 'utils/utils';

import { CLEAR_DATA } from '../common-action-types';
import * as ACTION_TYPES from './action-types';
import { LDAP_STATUS_LOADING } from '../../enum/constants';

const initialState = {
  isLoading: true,
  success: true,
  createdObject: null,
  psk: '',
  imgGenerating: {},
  address: [],
  things: [],
};

export function objectsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.FETCHING_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...state[payload.ecosystemUUID],
          objects: sortByName(payload.results),
        },
        isLoading: payload.isLoading,
        success: payload.success,
      };
    }
    case ACTION_TYPES.FETCHING_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
        success: payload.success,
      };
    }
    case ACTION_TYPES.FETCHING_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: sortByName(payload.results),
      };
    }
    case ACTION_TYPES.GET_THINGS_SUCCESS: {
      return {
        ...state,
        things: payload,
      };
    }
    case ACTION_TYPES.GENERATE_IMAGE_STARTED: {
      return {
        ...state,
        imgGenerating: {
          ...state.imgGenerating,
          [payload.uuid]: {
            ...state.imgGenerating[payload.uuid],
            [payload.platform]: true,
          },
        },
      };
    }
    case ACTION_TYPES.GENERATE_IMAGE_SUCCESS:
    case ACTION_TYPES.GENERATE_IMAGE_FAILURE:
      return {
        ...state,
        imgGenerating: {
          ...state.imgGenerating,
          [payload.uuid]: {
            ...state.imgGenerating[payload.uuid],
            [payload.platform]: false,
          },
        },
      };

    case ACTION_TYPES.CREATION_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: sortByName([payload.result, ...state[payload.ecosystem.uuid].objects]),
        },
      };
    }
    case ACTION_TYPES.CREATE_POLICY_OBJECT: {
      return {
        ...state,
        createdObject: { object: payload.object, field: payload.field },
      };
    }
    case ACTION_TYPES.CREATION_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case ACTION_TYPES.UPDATE_OBJECT_FAILURE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case ACTION_TYPES.UPDATE_OBJECT_SUCCESS: {
      return {
        ...state,
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: sortByName(
            state[payload.ecosystem.uuid].objects.map((ob) => (ob.uuid === payload.result.uuid ? payload.result : ob)),
          ),
        },
      };
    }
    case ACTION_TYPES.READ_GATEWAY_SUCCESS: {
      return {
        ...state,
        psk: '',
        [payload.ecosystem.uuid]: {
          ...state[payload.ecosystem.uuid],
          objects: sortByName(
            state[payload.ecosystem.uuid].objects.map((obj) =>
              obj.uuid === payload.result.uuid ? payload.result : obj,
            ),
          ),
        },
      };
    }
    case ACTION_TYPES.REGEN_PSK_SUCCESS: {
      return {
        ...state,
        psk: payload.psk,
      };
    }
    case ACTION_TYPES.GET_LDAP_TOKEN_SUCCESS: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...(state[payload.ecosystemUUID] ? state[payload.ecosystemUUID] : { objects: [] }),
          token: payload.token,
        },
      };
    }
    case ACTION_TYPES.LDAP_STATUS_REQUESTED: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...state[payload.ecosystemUUID],
          objects: state[payload.ecosystemUUID].objects.map((obj) =>
            obj.uuid === payload.uuid ? { ...obj, status: LDAP_STATUS_LOADING } : obj,
          ),
        },
      };
    }
    case ACTION_TYPES.LDAP_STATUS_SUCCESS: {
      return {
        ...state,
        [payload.ecosystemUUID]: {
          ...state[payload.ecosystemUUID],
          objects: state[payload.ecosystemUUID].objects.map((obj) =>
            obj.uuid === payload.uuid ? { ...obj, status: payload.status } : obj,
          ),
        },
      };
    }
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
