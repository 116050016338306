import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TimeWindows = ({ disabled = false, value, onChange, options }) => (
  <FormControl disabled={disabled}>
    <InputLabel sx={{ fontSize: 16 }} id="time-window">
      Time Window
    </InputLabel>
    <Select
      labelId="time-window"
      id="timeWindow"
      value={value}
      label="Time Window"
      onChange={(e) => onChange(e.target.value)}
      sx={{ fontSize: 16, width: 160 }}
    >
      {options.map((option) => (
        <MenuItem key={option.label} sx={{ fontSize: 16 }} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

TimeWindows.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default TimeWindows;
