import { createAction } from 'redux-actions';

import * as API from 'api/rest';
import { ECOSYSTEM_STATUS } from 'enum';
import { handlePromises } from 'utils/utils';
import { OBJECT_TYPES } from 'enum/object';
import * as ACTION_TYPES from './action-types';

export const createEcosystemGroupRequested = createAction(ACTION_TYPES.CREATE_ECOSYSTEM_GROUP_REQUESTED);
export const createEcosystemGroupSuccess = createAction(ACTION_TYPES.CREATE_ECOSYSTEM_GROUP_SUCCESS);
export function createEcosystemGroupFailure(err) {
  return {
    type: ACTION_TYPES.CREATE_ECOSYSTEM_GROUP_FAILURE,
    payload: {
      message: err,
    },
  };
}
export const createServiceRequested = createAction(ACTION_TYPES.CREATE_SERVICE_REQUESTED);
export const createServiceSuccess = createAction(ACTION_TYPES.CREATE_SERVICE_SUCCESS);
export function createServiceFailure(err) {
  return {
    type: ACTION_TYPES.CREATE_SERVICE_FAILURE,
    payload: {
      message: err,
    },
  };
}
export function createEcosystemStarted() {
  return {
    type: ACTION_TYPES.CREATE_ECOSYSTEM_REQUESTED,
  };
}
export function createEcosystemSuccess(result) {
  return {
    type: ACTION_TYPES.CREATE_ECOSYSTEM_SUCCESS,
    payload: result,
  };
}
export function createEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.CREATE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function fetchingEcosystemsStarted() {
  return {
    type: ACTION_TYPES.FETCHING_ECOSYSTEMS_REQUESTED,
  };
}
export function fetchingEcosystemsSuccess(results) {
  return {
    type: ACTION_TYPES.FETCHING_ECOSYSTEMS_SUCCESS,
    payload: results,
  };
}
export function fetchingEcosystemsFailure(err) {
  return {
    type: ACTION_TYPES.FETCHING_ECOSYSTEMS_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function fetchingNSPStarted() {
  return {
    type: ACTION_TYPES.FETCHING_NSP_REQUESTED,
  };
}
export function fetchingNSPSuccess(results) {
  return {
    type: ACTION_TYPES.FETCHING_NSP_SUCCESS,
    payload: results,
  };
}
export function fetchingNSPFailure(err) {
  return {
    type: ACTION_TYPES.FETCHING_NSP_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function loadEcosystemStarted() {
  return {
    type: ACTION_TYPES.LOAD_ECOSYSTEM_REQUESTED,
  };
}
export function loadEcosystemSuccess(data) {
  return {
    type: ACTION_TYPES.LOAD_ECOSYSTEM_SUCCESS,
    payload: data,
  };
}
export function loadEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.LOAD_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function refreshEcosystemStarted() {
  return {
    type: ACTION_TYPES.REFRESH_ECOSYSTEM_REQUESTED,
  };
}
export function refreshEcosystemSuccess(data) {
  return {
    type: ACTION_TYPES.REFRESH_ECOSYSTEM_SUCCESS,
    payload: data,
  };
}

export function removeEcosystemStarted() {
  return {
    type: ACTION_TYPES.REMOVE_ECOSYSTEM_REQUESTED,
  };
}
export function removeEcosystemSuccess() {
  return {
    type: ACTION_TYPES.REMOVE_ECOSYSTEM_SUCCESS,
  };
}
export function removeEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.REMOVE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function upgradeEcosystemStarted() {
  return {
    type: ACTION_TYPES.UPGRADE_ECOSYSTEM_REQUESTED,
  };
}
export function upgradeEcosystemSuccess() {
  return {
    type: ACTION_TYPES.UPGRADE_ECOSYSTEM_SUCCESS,
  };
}
export function upgradeEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.UPGRADE_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function recoverEcosystemStarted() {
  return {
    type: ACTION_TYPES.RECOVER_ECOSYSTEM_REQUESTED,
  };
}
export function recoverEcosystemSuccess() {
  return {
    type: ACTION_TYPES.RECOVER_ECOSYSTEM_SUCCESS,
  };
}
export function recoverEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.RECOVER_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function editEcosystemStarted() {
  return {
    type: ACTION_TYPES.EDIT_ECOSYSTEM_REQUESTED,
  };
}
export function editEcosystemSuccess(ecosystem) {
  return {
    type: ACTION_TYPES.EDIT_ECOSYSTEM_SUCCESS,
    payload: ecosystem,
  };
}
export function editEcosystemFailed(err) {
  return {
    type: ACTION_TYPES.EDIT_ECOSYSTEM_FAILURE,
    payload: {
      message: err,
    },
  };
}

export function getEcoUserStarted() {
  return { type: ACTION_TYPES.GET_ECOSYSTEM_USER_REQUESTED };
}
export function getEcoUserSuccess(users) {
  return { type: ACTION_TYPES.GET_ECOSYSTEM_USER_SUCCESS, payload: users };
}
export function getEcoUserFailed(err) {
  return { type: ACTION_TYPES.GET_ECOSYSTEM_USER_FAILURE, payload: { message: err } };
}

export function setCurrentEcosystem(ecosystem) {
  return {
    type: ACTION_TYPES.SET_CURRENT_ECOSYSTEM,
    payload: ecosystem,
  };
}
export function saveCommitID(payload) {
  return {
    type: ACTION_TYPES.SAVE_COMMIT_REQUEST,
    payload,
  };
}
export function saveRevertID(payload) {
  return {
    type: ACTION_TYPES.SAVE_REVERT_REQUEST,
    payload,
  };
}

export function grantAccessStarted() {
  return {
    type: ACTION_TYPES.GRANT_ACCESS_REQUESTED,
  };
}
export function grantAccessSuccess() {
  return {
    type: ACTION_TYPES.GRANT_ACCESS_SUCCESS,
  };
}
export function grantACcessFailed() {
  return {
    type: ACTION_TYPES.GRANT_ACCESS_FAILURE,
  };
}

export const resetRequestID = createAction(ACTION_TYPES.RESET_REQUEST_ID);

export function updateEcosystemMFADataStarted() {
  return {
    type: ACTION_TYPES.UPDATE_ECOSYSTEM_MFA_DATA_REQUESTED,
  };
}

export function updateEcosystemMFADataSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_ECOSYSTEM_MFA_DATA_SUCCESS,
    payload,
  };
}

export function updateEcosystemMFADataFailure(err) {
  return {
    type: ACTION_TYPES.UPDATE_ECOSYSTEM_MFA_DATA_FAILURE,
    payload: {
      message: err,
    },
  };
}
export const createEcosystemRoleRequested = createAction(ACTION_TYPES.CREATE_ECOSYSTEM_ROLE_REQUESTED);
export const createEcosystemRoleSuccess = createAction(ACTION_TYPES.CREATE_ECOSYSTEM_ROLE_SUCCESS);
export const createEcosystemRoleFailure = createAction(ACTION_TYPES.CREATE_ECOSYSTEM_ROLE_FAILURE);

export const setTimeWindow = createAction(ACTION_TYPES.SET_TIME_WINDOW);
export const setRefreshTimer = createAction(ACTION_TYPES.SET_REFESH_TIMER);
export const setDetailLoading = createAction(ACTION_TYPES.SET_DETAIL_LOADING);

export const getEcosystemReports =
  (timeStamp, time = 8) =>
  async (dispatch, getState) => {
    try {
      const customerUUID = getState().customers.currentCustomer.uuid;
      const { items } = getState().ecosystems;
      if (
        !items ||
        !items.length ||
        !items.some((item) =>
          [ECOSYSTEM_STATUS.PENDING_CHANGES, ECOSYSTEM_STATUS.OK, ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS].includes(
            item.status,
          ),
        )
      ) {
        dispatch({
          type: ACTION_TYPES.GET_ECOSYSTEM_USAGE_SUCCESS,
          payload: {},
        });
        return;
      }
      const now = new Date(timeStamp.from);
      const date = new Date(now);
      const payload = {
        customerUUID,
        ...timeStamp,
      };
      const [networkData, { TimeSeries: threatData }, { TimeSeries: blockedData }, threatIndex] = await handlePromises([
        API.getEcosystemReports({ ...payload, metric: 'network', aggregate: 'per_sec' }),
        API.getEcosystemReports({ ...payload, metric: 'threat' }),
        API.getEcosystemReports({ ...payload, metric: 'blocked' }),
        API.getEcosystemThreadIndex(payload),
      ]);
      const metrics = {};
      items.forEach(({ uuid, name }) => {
        metrics[uuid] = {
          name,
          network: {
            transmit: [
              { timeStamp: date.getTime(), value: 0 },
              { timeStamp: now.getTime(), value: 0 },
            ],
            receive: [
              { timeStamp: date.getTime(), value: 0 },
              { timeStamp: now.getTime(), value: 0 },
            ],
          },
          threat: {},
          blocked: [],
          threatIndex: {},
        };
      });

      if (networkData) {
        networkData.forEach(({ Attributes, Value: values }) => {
          const { direction, ecosystem_id: id } = Attributes;
          const usageData = [];
          if (values?.length) {
            const timeGap = (time * 60 * 60 * 1000) / values.length;
            const fromTime = now.getTime() - timeGap * (values.length - 1);
            values.forEach((val, index) => {
              usageData.push({ timeStamp: fromTime + index * timeGap, value: val });
            });
          }
          if (metrics[id]) {
            metrics[id].network[direction] = usageData;
          }
        });
      }
      if (threatData) {
        threatData.forEach(({ Attributes, Value }) => {
          const { ecosystem_id: id } = Attributes;
          const severity = Attributes.event_severity.toLowerCase();
          const severityData = [];
          if (Value?.length) {
            const timeGap = (time * 60 * 60 * 1000) / Value.length;
            const fromTime = now.getTime() - timeGap * (Value.length - 1);
            Value.forEach((val, index) => {
              severityData.push({ timeStamp: fromTime + index * timeGap, value: val });
            });
          }
          if (metrics[id]) {
            metrics[id].threat[severity] = severityData;
          }
        });
      }
      if (blockedData) {
        blockedData.forEach(({ Attributes, Value }) => {
          const { ecosystem_id: id } = Attributes;
          const blocked = [];
          if (Value?.length) {
            const timeGap = (time * 60 * 60 * 1000) / Value.length;
            const fromTime = now.getTime() - timeGap * (Value.length - 1);
            Value.forEach((val, index) => {
              blocked.push({ timeStamp: fromTime + index * timeGap, value: val });
            });
          }
          if (metrics[id]) {
            metrics[id].blocked = blocked;
          }
        });
      }
      if (threatIndex && threatIndex.length) {
        threatIndex.forEach(({ Ecosystem: id, External: external, Internal: internal }) => {
          const indexData = { external, internal, average: (external + internal) / 2 };
          if (metrics[id]) {
            metrics[id].threatIndex = indexData;
          }
        });
      }
      dispatch({
        type: ACTION_TYPES.GET_ECOSYSTEM_USAGE_SUCCESS,
        payload: metrics,
      });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_USAGE_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemDetail =
  ({ timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const [
        ecoUsage,
        utilTopApp,
        utilTopUser,
        utilTopSrc,
        utilTopDest,
        threatTopThreats,
        thratTopUsers,
        threatTopSrc,
        threatTopDest,
        threatData,
      ] = await handlePromises([
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'network',
          aggregate: 'per_sec',
          attributes: 'direction',
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'network',
          aggregate: 'per_sec',
          attributes: 'application',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'network',
          aggregate: 'per_sec',
          attributes: 'user',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'network',
          aggregate: 'per_sec',
          attributes: 'source_fqdn',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'network',
          aggregate: 'per_sec',
          attributes: 'destination_fqdn',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'threat',
          attributes: 'details',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'threat',
          attributes: 'user',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'threat',
          attributes: 'source_fqdn',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'threat',
          attributes: 'destination_fqdn',
          limit: 3,
        }),
        API.getEcosystemMetrcis({
          customerUUID,
          ecosystemUUID,
          ...timeStamp,
          metric: 'threat',
          attributes: 'event_severity',
        }),
      ]);
      const result = {
        network: {
          application: utilTopApp,
          user: utilTopUser,
          source_fqdn: utilTopSrc,
          destination_fqdn: utilTopDest,
          direction: ecoUsage,
        },
        threat: {
          details: threatTopThreats,
          user: thratTopUsers,
          source_fqdn: threatTopSrc,
          destination_fqdn: threatTopDest,
          event_severity: threatData,
        },
      };
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_FAILURE, payload: { message: err } });
    }
  };

export const geEcosystemForDetail =
  ({ ecosystemUUID }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_FOR_DETAIL_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystem = await API.fetchEcosystem({ customerUUID, ecosystemUUID });
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_FOR_DETAIL_SUCCESS, payload: ecosystem });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_FOR_DETAIL_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemDetailCounters =
  ({ timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_COUNTERS_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const ecoDetail = await API.getEcosystemDashboardDetail({ customerUUID, ecosystemUUID, ...timeStamp });
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_COUNTERS_SUCCESS, payload: ecoDetail.Counters });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_COUNTERS_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemDetailIndex =
  ({ timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_THREATINDEX_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const ecoIndex = await API.getEcosystemDashboardIndex({ customerUUID, ecosystemUUID, ...timeStamp });
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_THREATINDEX_SUCCESS, payload: ecoIndex ? ecoIndex[0] : null });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_THREATINDEX_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemUsage =
  ({ timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_USAGE_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const ecoUsage = await API.getEcosystemMetrcis({
        customerUUID,
        ecosystemUUID,
        ...timeStamp,
        metric: 'network',
        aggregate: 'per_sec',
        attributes: 'direction',
      });
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_USAGE_SUCCESS, payload: ecoUsage });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_DETAIL_USAGE_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemThreatData =
  ({ timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_THREAT_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const threatData = await API.getEcosystemMetrcis({
        customerUUID,
        ecosystemUUID,
        ...timeStamp,
        metric: 'threat',
        attributes: 'event_severity',
      });
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_THREAT_SUCCESS, payload: threatData });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_THREAT_FAILURE, payload: { message: err } });
    }
  };

export const getEcosystemNetworkMetric =
  ({ attributes, timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_NETWORK_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const metric = await API.getEcosystemMetrcis({
        customerUUID,
        ecosystemUUID,
        ...timeStamp,
        metric: 'network',
        aggregate: 'per_sec',
        attributes,
        limit: 3,
      });
      dispatch({
        type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_NETWORK_SUCCESS,
        payload: { [attributes]: metric, key: attributes },
      });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_NETWORK_FAILURE, payload: { message: err, key: attributes } });
    }
  };

export const getEcosystemThreatMetric =
  ({ attributes, timeStamp }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_THREAT_REQUEST });
      const customerUUID = getState().customers.currentCustomer.uuid;
      const ecosystemUUID = getState().ecosystems.ecosystem.uuid;
      const metric = await API.getEcosystemMetrcis({
        customerUUID,
        ecosystemUUID,
        ...timeStamp,
        metric: 'threat',
        attributes,
        limit: 3,
      });
      dispatch({
        type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_THREAT_SUCCESS,
        payload: { [attributes]: metric, key: attributes },
      });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_ECOSYSTEM_METRIC_THREAT_FAILURE, payload: { message: err, key: attributes } });
    }
  };

export const getGatewayLogs =
  ({ customerUUID, ecosystemUUID, ...timeStamp }) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_TYPES.GET_VGW_LOGS_REQUEST });
      const customer = { uuid: customerUUID };
      const [gateways, logs] = await handlePromises(
        [
          API.fetchObjects({ customer, ecosystem: ecosystemUUID, type: OBJECT_TYPES.GATEWAY }),
          API.getGatewayLogs({ customerUUID, ...timeStamp }),
        ],
        [[], []],
      );
      const results = logs
        .filter((log) => log.ecosystem_id === ecosystemUUID)
        .map((log) => ({ ...log, vgw: gateways.find(({ uuid }) => uuid === log.vgw_uuid) }));
      dispatch({ type: ACTION_TYPES.GET_VGW_LOGS_SUCCESS, payload: results });
    } catch (err) {
      dispatch({ type: ACTION_TYPES.GET_VGW_LOGS_FAILURE, payload: { message: err } });
    }
  };
