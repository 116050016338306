import Cookie from 'js-cookie';
import sortBy from 'lodash/sortBy';
import { CLEAR_DATA } from '../common-action-types';
import * as ACTION_TYPES from './action-types';

const initialState = {
  items: [],
  ecoUsers: [],
  timeWindow: 8,
  refresh: 0,
  ecosystem: {
    countData: null,
    threatIndex: null,
    network: {},
    threat: {},
    loading: {
      index: false,
      network: {},
      threat: {},
      count: false,
    },
  },
  metrics: {},
  currentEcosystem: Cookie.get('currentEcosystem') ? JSON.parse(Cookie.get('currentEcosystem')) : null,
  dictionaries: {
    groups: [],
    roles: [],
    nsps: [],
    applications: [],
    services: [],
  },
  createdService: null,
  gatewayLogs: [],
};

export function ecosystemsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.CREATE_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        items: sortBy([payload, ...state.items], (eco) => eco.name),
      };
    }
    case ACTION_TYPES.EDIT_ECOSYSTEM_SUCCESS: {
      const ecosystems = state.items;
      const index = ecosystems.findIndex(({ uuid }) => uuid === payload.uuid);
      ecosystems[index] = { ...ecosystems[index], name: payload.name };
      return {
        ...state,
        items: [...ecosystems],
      };
    }
    case ACTION_TYPES.CREATE_ECOSYSTEM_GROUP_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          groups: payload,
        },
      };
    }
    case ACTION_TYPES.CREATE_SERVICE_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          services: [...state.dictionaries.services, payload],
        },
        createdService: payload,
      };
    }
    case CLEAR_DATA: {
      Cookie.remove('currentEcosystem');
      return initialState;
    }
    case ACTION_TYPES.FETCHING_ECOSYSTEMS_SUCCESS: {
      return {
        ...state,
        items: sortBy(payload, (eco) => eco.name),
      };
    }
    case ACTION_TYPES.FETCHING_ECOSYSTEMS_FAILURE: {
      return {
        ...state,
        items: [],
      };
    }
    case ACTION_TYPES.FETCHING_NSP_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          nsps: payload,
        },
      };
    }
    case ACTION_TYPES.LOAD_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          groups: payload.groups,
          applications: payload.applications,
          services: payload.services,
          nsps: payload.nsps,
        },
      };
    }
    case ACTION_TYPES.REFRESH_ECOSYSTEM_SUCCESS: {
      return {
        ...state,
        currentEcosystem: {
          ...state.currentEcosystem,
          status: payload.status,
        },
      };
    }
    case ACTION_TYPES.RESET_REQUEST_ID: {
      const { currentEcosystem, items } = state;
      return {
        ...state,
        items: items.map((item) =>
          item.uuid === payload.ecoId ? { ...item, commitID: '', revertID: '', commitStatus: '' } : item,
        ),
        currentEcosystem:
          currentEcosystem.uuid === payload.ecoId
            ? { ...currentEcosystem, commitID: '', revertID: '', commitStatus: '' }
            : currentEcosystem,
      };
    }
    case ACTION_TYPES.SAVE_COMMIT_REQUEST: {
      const { currentEcosystem } = state;
      return {
        ...state,
        items: state.items.map((item) =>
          item.uuid === payload.ecoId
            ? { ...item, commitID: payload.reqID, status: payload.status, commitStatus: payload.status }
            : item,
        ),
        currentEcosystem:
          currentEcosystem.uuid === payload.ecoId
            ? { ...currentEcosystem, commitID: payload.reqID, status: payload.status, commitStatus: payload.status }
            : currentEcosystem,
      };
    }
    case ACTION_TYPES.SAVE_REVERT_REQUEST: {
      const { currentEcosystem } = state;
      return {
        ...state,
        items: state.items.map((item) =>
          item.uuid === payload.ecoId ? { ...item, revertID: payload.reqID, status: payload.status } : item,
        ),
        currentEcosystem:
          currentEcosystem.uuid === payload.ecoId
            ? { ...currentEcosystem, revertID: payload.reqID, status: payload.status }
            : currentEcosystem,
      };
    }
    case ACTION_TYPES.SET_CURRENT_ECOSYSTEM: {
      return {
        ...state,
        currentEcosystem: payload,
      };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_USER_REQUESTED: {
      return { ...state, ecoUsers: [] };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_USER_SUCCESS: {
      return { ...state, ecoUsers: payload };
    }
    case ACTION_TYPES.GET_ACCESS_USER_SUCCESS:
      return {
        ...state,
        currentEcosystem: { ...state.currentEcosystem, users: payload.users },
      };
    case ACTION_TYPES.CREATE_ECOSYSTEM_ROLE_SUCCESS: {
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          roles: [...state.dictionaries.roles, payload],
        },
      };
    }
    case ACTION_TYPES.SET_ECOSYSTEM_CODE: {
      return {
        ...state,
        currentEcosystem: {
          ...state.currentEcosystem,
          ecosystemCode: payload.code,
        },
      };
    }
    case ACTION_TYPES.SET_ECOSYSTEM_MFA_DATA:
    case ACTION_TYPES.UPDATE_ECOSYSTEM_MFA_DATA_SUCCESS: {
      return {
        ...state,
        currentEcosystem: {
          ...state.currentEcosystem,
          mfaData: { ...state.currentEcosystem.mfaData, ...payload.mfaData },
        },
      };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_USAGE_SUCCESS: {
      return {
        ...state,
        metrics: payload,
      };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_USAGE_FAILURE: {
      return {
        ...state,
        metrics: {},
      };
    }
    case ACTION_TYPES.SET_DETAIL_LOADING: {
      return {
        ...state,
        ecosystem: {
          ...state.ecosystem,
          loading: {
            index: true,
            network: {
              application: true,
              user: true,
              destination_fqdn: true,
              source_fqdn: true,
              direction: true,
            },
            threat: {
              details: true,
              user: true,
              destination_fqdn: true,
              source_fqdn: true,
              event_severity: true,
            },
            count: true,
          },
        },
      };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_FOR_DETAIL_SUCCESS: {
      return {
        ...state,
        ecosystem: { ...state.ecosystem, ...payload },
      };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_COUNTERS_SUCCESS: {
      state.ecosystem.countData = payload;
      state.ecosystem.loading.count = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_COUNTERS_FAILURE: {
      state.ecosystem.loading.count = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_THREATINDEX_SUCCESS: {
      state.ecosystem.threatIndex = payload;
      state.ecosystem.loading.index = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_THREATINDEX_FAILURE: {
      state.ecosystem.loading.index = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_USAGE_SUCCESS: {
      state.ecosystem.network.direction = payload;
      state.ecosystem.loading.network.direction = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_DETAIL_USAGE_FAILURE: {
      state.ecosystem.loading.network.direction = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_METRIC_NETWORK_SUCCESS: {
      const { key, ...data } = payload;
      state.ecosystem.network = { ...state.ecosystem.network, ...data };
      state.ecosystem.loading.network[key] = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_METRIC_NETWORK_FAILURE: {
      const { key } = payload;
      state.ecosystem.loading.network[key] = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_THREAT_SUCCESS: {
      state.ecosystem.threat.event_severity = payload;
      state.ecosystem.loading.threat.event_severity = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_THREAT_FAILURE: {
      state.ecosystem.loading.threat.event_severity = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_METRIC_THREAT_SUCCESS: {
      const { key, ...data } = payload;
      state.ecosystem.threat = { ...state.ecosystem.threat, ...data };
      state.ecosystem.loading.threat[key] = false;
      return { ...state };
    }
    case ACTION_TYPES.GET_ECOSYSTEM_METRIC_THREAT_FAILURE: {
      const { key } = payload;
      state.ecosystem.loading.threat[key] = false;
      return { ...state };
    }
    case ACTION_TYPES.SET_TIME_WINDOW: {
      return {
        ...state,
        timeWindow: payload,
      };
    }
    case ACTION_TYPES.GET_VGW_LOGS_SUCCESS: {
      return {
        ...state,
        gatewayLogs: payload,
      };
    }
    case ACTION_TYPES.SET_REFESH_TIMER: {
      return {
        ...state,
        refresh: payload,
      };
    }
    default:
      return state;
  }
}
