/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';

import PageTitleWrapper from 'components/PageTitleWrapper';
import TimeWindows from 'components/TimeWindows';
import RefreshTimer from 'components/RefreshTimer';
import { getEcosystemReports, setTimeWindow } from 'store/ecosystems/actions';

import ChartCard from './ChartCard';
import CardHeader from './CardHeader';

const getTimeStamp = (timeWindow) => {
  const date = new Date();
  return {
    to: date.toISOString(),
    from: new Date(date.setHours(date.getHours() - timeWindow)).toISOString(),
  };
};

const timeWindowOptions = [
  { label: '8 hours', value: 8 },
  { label: '24 hours', value: 24 },
];

const EcosystemDashboard = ({ getEcosystemReports, setTimeWindow }) => {
  const { metrics, timeWindow, refresh } = useSelector((state) => state.ecosystems);

  const [loading, setLoading] = useState(false);
  const [timeStamp, setTimeStamp] = useState(getTimeStamp(timeWindow));
  const [intervalId, setIntervalId] = useState(null);

  const handleRefresh = useCallback(() => {
    setTimeStamp(getTimeStamp(timeWindow));
  }, [timeWindow]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh, timeWindow]);

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (refresh) {
      const interval = setInterval(() => {
        handleRefresh();
      }, refresh * 60 * 1000);
      setIntervalId(interval);
    }
    return () => clearInterval(intervalId);
  }, [refresh]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      await getEcosystemReports(timeStamp, timeWindow);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [getEcosystemReports, timeStamp]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <PageTitleWrapper size="false">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Typography variant="h3" sx={{ pr: 4 }}>
              Summary Dashboard
            </Typography>
            <TimeWindows
              disabled={loading}
              options={timeWindowOptions}
              value={timeWindow}
              onChange={(value) => setTimeWindow(value)}
            />
          </Stack>
          <RefreshTimer disabled={loading} value={refresh} onRefresh={handleRefresh} />
        </Stack>
      </PageTitleWrapper>
      <Container maxWidth="false" sx={{ height: 'fit-content', marginBottom: 4 }}>
        {!metrics || loading ? (
          <Box sx={{ height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card>
            <CardContent>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <CardHeader timeWindow={timeWindow} />
                </Grid>
                {Object.keys(metrics).map((uuid) => (
                  <Grid item xs={12} key={uuid} data-testid="ecosystem-detail-card">
                    <ChartCard uuid={uuid} metrics={metrics[uuid]} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>
    </>
  );
};

EcosystemDashboard.propTypes = {
  getEcosystemReports: PropTypes.func,
  setTimeWindow: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getEcosystemReports: (timeStamp, timeWindow) => dispatch(getEcosystemReports(timeStamp, timeWindow)),
  setTimeWindow: (value) => dispatch(setTimeWindow(value)),
});

export default connect(null, mapDispatchToProps)(EcosystemDashboard);
