/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';

import PageTitleWrapper from 'components/PageTitleWrapper';
import ExpiryWarning from 'components/ExpiryWarning';
import TimeWindows from 'components/TimeWindows';
import RefreshTimer from 'components/RefreshTimer';
import EcosystemHeader from 'components/Header/EcosystemHeader';
import {
  geEcosystemForDetail,
  getEcosystemDetailIndex,
  getEcosystemUsage,
  getEcosystemThreatData,
  getEcosystemNetworkMetric,
  getEcosystemThreatMetric,
  getEcosystemDetailCounters,
  setTimeWindow,
  setDetailLoading,
} from 'store/ecosystems/actions';
import { ROUTES } from 'enum';

import Utilization from './Utilization';
import Security from './Security';

const getTimeStamp = (timeWindow) => {
  const date = new Date();
  return {
    to: date.toISOString(),
    from: new Date(date.setHours(date.getHours() - timeWindow)).toISOString(),
  };
};

const timeWindowOptions = [
  { label: '8 hours', value: 8 },
  { label: '24 hours', value: 24 },
];

const EcosystemDetail = ({
  geEcosystemForDetail,
  getEcosystemDetailIndex,
  getEcosystemUsage,
  getEcosystemNetworkMetric,
  getEcosystemThreatMetric,
  getEcosystemThreatData,
  getEcosystemDetailCounters,
  setTimeWindow,
  setDetailLoading,
}) => {
  const history = useHistory();
  const { cusID, ecoID } = useParams();

  const { ecosystem, timeWindow, refresh } = useSelector((state) => state.ecosystems);

  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [timeStamp, setTimeStamp] = useState(getTimeStamp(timeWindow));

  const handleRefresh = useCallback(() => {
    setTimeStamp(getTimeStamp(timeWindow));
  }, [timeWindow]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh, timeWindow]);

  const getData = useCallback(async () => {
    setDetailLoading();
    await getEcosystemDetailIndex(timeStamp);
    await getEcosystemUsage(timeStamp);
    await getEcosystemNetworkMetric(timeStamp, 'application');
    await getEcosystemNetworkMetric(timeStamp, 'user');
    await getEcosystemNetworkMetric(timeStamp, 'destination_fqdn');
    await getEcosystemNetworkMetric(timeStamp, 'source_fqdn');
    await getEcosystemThreatMetric(timeStamp, 'details');
    await getEcosystemThreatMetric(timeStamp, 'user');
    await getEcosystemThreatMetric(timeStamp, 'destination_fqdn');
    await getEcosystemThreatMetric(timeStamp, 'source_fqdn');
    await getEcosystemThreatData(timeStamp);
    await getEcosystemDetailCounters(timeStamp);
  }, [timeStamp, ecosystem.uuid]);

  useEffect(() => {
    if (ecosystem?.uuid) {
      getData();
    }
  }, [timeStamp, ecosystem.uuid]);

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (refresh) {
      const interval = setInterval(() => {
        handleRefresh();
      }, refresh * 60 * 1000);
      setIntervalId(interval);
    }
    return () => clearInterval(intervalId);
  }, [refresh]);

  const getEcosystem = useCallback(async () => {
    try {
      setLoading(true);
      await geEcosystemForDetail({ ecosystemUUID: ecoID });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [geEcosystemForDetail, ecoID]);

  useEffect(() => {
    getEcosystem();
  }, [getEcosystem]);

  const handleChangeTab = (tab) => {
    if (tab === 'summary') history.replace(`${ROUTES.CUSTOMERS}/${cusID}/ecosystems/dashboard`);
    else history.replace(`${ROUTES.CUSTOMERS}/${cusID}/ecosystems`);
  };

  return (
    <div className="wrapper ecosystems-wrapper">
      <ExpiryWarning />
      <EcosystemHeader onChangeTab={handleChangeTab} activeTab="summary" />
      <div style={{ width: '100%' }}>
        <PageTitleWrapper size="false">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h3" sx={{ pr: 4 }}>
                {ecosystem ? ecosystem.name : ''}
              </Typography>
              <TimeWindows
                disabled={loading}
                options={timeWindowOptions}
                value={timeWindow}
                onChange={(value) => setTimeWindow(value)}
              />
            </Stack>
            <RefreshTimer disabled={loading} value={refresh} onRefresh={handleRefresh} />
          </Stack>
        </PageTitleWrapper>
        <Container maxWidth="false">
          {!ecosystem.uuid || loading ? (
            <Box sx={{ height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={4}>
              <Grid item md={12} lg={6}>
                <Utilization timeStamp={timeStamp} />
              </Grid>
              <Grid item md={12} lg={6}>
                <Security timeStamp={timeStamp} />
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    </div>
  );
};

EcosystemDetail.propTypes = {
  geEcosystemForDetail: PropTypes.func,
  getEcosystemDetailIndex: PropTypes.func,
  getEcosystemUsage: PropTypes.func,
  getEcosystemThreatData: PropTypes.func,
  getEcosystemNetworkMetric: PropTypes.func,
  getEcosystemThreatMetric: PropTypes.func,
  getEcosystemDetailCounters: PropTypes.func,
  setTimeWindow: PropTypes.func,
  setDetailLoading: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  geEcosystemForDetail: (timeWindow) => dispatch(geEcosystemForDetail(timeWindow)),
  getEcosystemDetailIndex: (timeStamp) => dispatch(getEcosystemDetailIndex({ timeStamp })),
  getEcosystemUsage: (timeStamp) => dispatch(getEcosystemUsage({ timeStamp })),
  getEcosystemThreatData: (timeStamp) => dispatch(getEcosystemThreatData({ timeStamp })),
  getEcosystemNetworkMetric: (timeStamp, attributes) => dispatch(getEcosystemNetworkMetric({ timeStamp, attributes })),
  getEcosystemThreatMetric: (timeStamp, attributes) => dispatch(getEcosystemThreatMetric({ timeStamp, attributes })),
  getEcosystemDetailCounters: (timeStamp) => dispatch(getEcosystemDetailCounters({ timeStamp })),
  setDetailLoading: () => dispatch(setDetailLoading()),
  setTimeWindow: (value) => dispatch(setTimeWindow(value)),
});

export default connect(null, mapDispatchToProps)(EcosystemDetail);
